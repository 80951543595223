export const navElements = () => {
  return [
    {to: '/about',name: 'About'},
    {to: '/projects',name: 'Projects'},
    {to: '/skills', name: 'Skills'},
    {to: '/blog',name: 'Blog'},
    {to: '/now',name: 'Now'},
    {to: '/contact', name:'Contact'},
    
  ];
}